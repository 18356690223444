var exports = {};

/**
 * Determines whether the payload is an error thrown by Axios
 *
 * @param {*} payload The value to test
 * @returns {boolean} True if the payload is an error thrown by Axios, otherwise false
 */
exports = function isAxiosError(payload) {
  return typeof payload === "object" && payload.isAxiosError === true;
};

export default exports;